<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card v-if="cards">
        <v-card-title class="text-h5">
          {{ this.$t('orderModals.assignTokens') }} {{ counter }}/{{ cards.length}}
        </v-card-title>
        <v-row no-gutters align="center">
          <v-col cols="2">
            <v-btn small v-if="counter > 1" @click="counter--" class="float-right" :disabled="loading" depressed rounded>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-card-text>
              <v-form ref="addForm" v-model="valid" @submit.prevent>
                <v-text-field v-model="cards[counter-1].token" @change="nextInput($event, counter-1, cards[counter-1])" :id="`token-${counter-1}`" dense class="py-2"
                :label="`${cards[counter-1].shoppingCenterName} - ${cards[counter-1].chargeAmount.toFixed(2)} ${getLocalCurrency}`" :rules="required" :autofocus="(counter-1) === 0" validate-on-blur></v-text-field>
              </v-form>
            </v-card-text>
          </v-col>
          <v-col cols="2">
            <v-btn small v-if="counter !== cards.length" @click="counter++" color="secondary" :disabled="loading || !valid" :loading="loading" depressed rounded>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="close" :disabled="loading" depressed rounded>
            {{ this.$t('twoFactorAuthentication.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="add" :disabled="loading || counter < cards.length || !valid" :loading="loading" depressed rounded>
            {{ this.$t('twoFactorAuthentication.save') }}
          </v-btn>
        </v-card-actions>
        <!-- <v-card-text class="mt-4">
          <v-form ref="addForm" v-model="valid" @submit.prevent>
            <div v-for="(card, i) in cards" :key="card.id">
              <v-text-field v-model="card.token" @change="nextInput($event, i, card)" :id="`token-${i}`" dense class="py-2" :label="`${card.shoppingCenterName} - ${card.chargeAmount.toFixed(2)} ${getLocalCurrency}`" :rules="required" :autofocus="i === 0"></v-text-field>
            </div>
          </v-form>
        </v-card-text> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Service from '@/services'
export default {
  name: 'Add',
  data () {
    return {
      dialog: false,
      valid: true,
      loading: false,
      id: null,
      cards: null,
      counter: 1,
      required: [
        (value) => !!value || this.$t('validation.fieldRequired'),
        v => /^[0-9]{9}$/.test(v) || this.$t('validation.incorrectToken'),
        (value) => (value || '').length === 9 || this.$t('validation.tokenHas9Digits') // ,
        // (value) => !this.cards.find((c) => c.token === value) || 'Tokeny kart muszą być unikatowe'
      ]
    }
  },
  computed: {
    getLocalCurrency () {
      return localStorage.getItem('currency')
    }
  },
  methods: {
    show (cards, id) {
      this.cards = JSON.parse(JSON.stringify(cards))
      // this.cards = cards.map(card => { return { id: card.id, token: card.token } })
      this.id = id
      this.dialog = true
      // const tokenInput = document.getElementById('token-0')
      // console.log(tokenInput)
      // if (tokenInput) tokenInput.focus()
    },
    async add () {
      this.$refs.addForm.validate()

      if (!this.valid) return

      this.loading = true
      try {
        const cards = this.cards.map(card => { return { id: card.id, token: card.token } })
        await Service.post(`/Orders/${this.id}/AssignCardTokens`, { id: this.id, cards: cards })

        this.$refs.addForm.reset()

        this.$emit('refresh')

        this.dialog = false
      } catch (error) {}
      this.loading = false
    },
    nextInput (value, id, card) {
      if (!value) return

      const token = value.split('/')

      // const find = this.cards.find((c) => c.token === token[token.length - 1])
      // const filter = this.cards.filter(card => card.token === token[token.length - 1])
      // console.log(filter)
      card.token = token[token.length - 1]

      // if (find) return

      if (this.counter < this.cards.length && card.token.length === 9 && /^[0-9]{9}$/.test(card.token)) {
        this.counter++
      } else {
        document.getElementById(`token-${this.counter - 1}`).blur()
      }
    },
    close () {
      this.$refs.addForm.reset()
      this.counter = 1
      this.cards = null
      this.dialog = false
    }
  }
}
</script>
